'use client';

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { Fragment, ReactNode, useEffect, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  isOpen: boolean;
  size?: 'full' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  position?:
    | 'top'
    | 'center'
    | 'bottom'
    | 'top-left'
    | 'top-right'
    | 'bottom-right'
    | 'bottom-left';
  transition?: 'top' | 'center' | 'bottom' | 'left' | 'right';
  close: () => void;
  className?: string;
  containerClass?: string;
  children?: ReactNode | string;
  style?: any;
}

interface TransitionProps {
  enter: string;
  enterFrom: string;
  enterTo: string;
  leave: string;
  leaveFrom: string;
  leaveTo: string;
}

export default function ModalComponent({
  isOpen,
  close,
  size,
  className,
  children,
  containerClass,
  position,
  transition,
  style,
}: Props) {
  useEffect(() => {
    const mainElement = document.querySelector('main');

    if (isOpen) {
      // Set inert on the main tag
      if (mainElement) {
        mainElement.removeAttribute('inert');
      }
    } else {
      // Remove inert from the main tag
      if (mainElement) {
        mainElement.removeAttribute('inert');
      }
    }

    return () => {
      // Clean up on unmount
      if (mainElement) {
        mainElement.removeAttribute('inert');
      }
    };
  }, [isOpen]);
  const sizes = useMemo(() => {
    let _size = size || 'sm';
    let result = '';
    switch (_size) {
      case 'full':
        result = `max-w-full`;
        break;
      case 'sm':
        result = `sm:max-w-lg`;
        break;
      case 'md':
        result = `sm:max-w-2xl`;
        break;
      case 'lg':
        result = `sm:max-w-3xl`;
        break;
      case 'xl':
        result = `sm:max-w-4xl`;
        break;
      case '2xl':
        result = `sm:max-w-6xl`;
        break;
    }
    return result;
  }, [size]);

  const positions = useMemo(() => {
    let _position = position || 'center';
    let result = '';

    switch (_position) {
      case 'top':
        result = 'items-start justify-center';
        break;
      case 'top-left':
        result = 'items-start justify-start';
        break;
      case 'top-right':
        result = 'items-start justify-end';
        break;
      case 'center':
        result = 'items-center justify-center';
        break;
      case 'bottom':
        result = 'items-end justify-center';
        break;
      case 'bottom-left':
        result = 'items-end justify-start';
        break;
      case 'bottom-right':
        result = 'items-end justify-end';
        break;
    }
    return result;
  }, [position]);

  const transitions: TransitionProps = useMemo(() => {
    let _transition = transition || 'center';
    let result: TransitionProps | any = {};

    switch (_transition) {
      case 'top':
        result = {
          enter: 'transition ease-in-out duration-300 transform',
          enterFrom: 'opacity-0 translate-y-[-10%]',
          enterTo: 'opacity-100 translate-y-0',
          leave: 'transition ease-in-out duration-300 transform',
          leaveFrom: 'opacity-100 translate-y-0',
          leaveTo: 'opacity-0 translate-y-[-10%]',
        };
        break;
      case 'bottom':
        result = {
          enter: 'transition ease-in-out duration-300 transform',
          enterFrom: 'opacity-0 translate-y-[10%]',
          enterTo: 'opacity-100 translate-y-0',
          leave: 'transition ease-in-out duration-300 transform',
          leaveFrom: 'opacity-100 translate-y-0',
          leaveTo: 'opacity-0 translate-y-[10%]',
        };
        break;
      case 'center':
        result = {
          enter: 'ease-in-out duration-300',
          enterFrom: 'opacity-0 scale-95',
          enterTo: 'opacity-100 scale-100',
          leave: 'ease-in-out duration-300',
          leaveFrom: 'opacity-100 scale-100',
          leaveTo: 'opacity-0 scale-95',
        };
        break;
      case 'right':
        result = {
          enter: 'transition ease-in-out duration-300 transform',
          enterFrom: 'opacity-0 translate-x-[10%]',
          enterTo: 'opacity-100 translate-x-0',
          leave: 'transition ease-in-out duration-300 transform',
          leaveFrom: 'opacity-100 translate-x-0',
          leaveTo: 'opacity-0 translate-x-[10%]',
        };
        break;
      case 'left':
        result = {
          enter: 'transition ease-in-out duration-300 transform',
          enterFrom: 'opacity-0 translate-x-[-10%]',
          enterTo: 'opacity-100 translate-x-0',
          leave: 'transition ease-in-out duration-300 transform',
          leaveFrom: 'opacity-100 translate-x-0',
          leaveTo: 'opacity-0 translate-x-[-10%]',
        };
        break;
    }

    return result;
  }, [transition]);

  return (
    <Fragment>
      <Transition appear show={isOpen}>
        <Dialog
          as='div'
          className='relative z-50 focus:outline-none'
          onClose={close}
          style={{ zIndex: '1000' }}
        >
          {/* <DialogBackdrop className="fixed inset-0 bg-black opacity-30 mt-16" /> */}
          <div
            style={style}
            className='fixed inset-0 z-10 w-screen overflow-y-auto bg-black/25'
          >
            <div
              className={`flex min-h-full ${twMerge(positions, containerClass)}`}
            >
              <TransitionChild
                enter={transitions.enter}
                enterFrom={transitions.enterFrom}
                enterTo={transitions.enterTo}
                leave={transitions.leave}
                leaveFrom={transitions.leaveFrom}
                leaveTo={transitions.leaveTo}
              >
                <DialogPanel
                  className={`w-full rounded-3xl bg-white ${twMerge(sizes, className)}`}
                >
                  {children}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
}
